import { FunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InsuranceFee } from "../../../../../../data/dataFees";
import { CostBodyCell } from "../../../components/costBodyCell/CostBodyCell";
import { CostHeaderCell } from "../../../components/costHeaderCell/CostHeaderCell";
import { ExpandableTable } from "../../components/expandableTable/ExpandableTable";
import { FeeBodyCell } from "../../../components/feeBodyCell/FeeBodyCell";
import { FeeHeaderCell } from "../../../components/feeHeaderCell/FeeHeaderCell";

const messages = defineMessages({
  insuranceFee: {
    id: "fees.feeTables.activeAccount.accountRow.insuranceFeesTable.insuranceFee",
  },
  riskPremiumFee: {
    id: "fees.feeTables.activeAccount.accountRow.insuranceFeesTable.riskPremiumFee",
  },
  avgCost: {
    id: "fees.feeTables.activeAccount.accountRow.insuranceFeesTable.avgCost",
  },
});

interface Props {
  accountData: InsuranceFee;
  isOpen: boolean;
  columns: number;
}

export const InsuranceFeesTable: FunctionComponent<Props> = ({
  accountData,
  isOpen,
  columns,
}) => {
  const intl = useIntl();

  return (
    <ExpandableTable isOpen={isOpen} parentCells={columns}>
      <thead>
        <tr>
          <FeeHeaderCell text={intl.formatMessage(messages.insuranceFee)} />
          {accountData.riskPremiumFee && (
            <FeeHeaderCell text={intl.formatMessage(messages.riskPremiumFee)} />
          )}
          <CostHeaderCell text={intl.formatMessage(messages.avgCost)} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <FeeBodyCell
            value={accountData.insuranceFee}
            label={intl.formatMessage(messages.insuranceFee)}
          />
          {accountData.riskPremiumFee && (
            <FeeBodyCell
              value={accountData.riskPremiumFee}
              label={intl.formatMessage(messages.riskPremiumFee)}
            />
          )}
          <CostBodyCell
            value={accountData.avgCost}
            label={intl.formatMessage(messages.avgCost)}
          />
        </tr>
      </tbody>
    </ExpandableTable>
  );
};
